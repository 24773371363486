import React, { useContext } from "react"
import { LanguageContext } from "../../context"
import Banner from "../banner"
import Button from "../button-visit-us"
import Button2 from "../button-generic"
import ButtonModal from "../buttons/button-volunteer"
import { navigate } from "gatsby"
import { useBreakpoint } from "gatsby-plugin-breakpoints"
import DynamicLinks  from "../links/dynamic-links"
import Icon from "../icon"


const stripePaymentUrl = "https://donate.stripe.com/14k9DZgnhdWM4Eg7sx"

const ComplexPage = (props) => {

  const {
    post,
    bannerHeight="408px",
    buttonBanner,
    contents,
    multipleButtons
  } = props
  const { t } = useContext(LanguageContext)
  const breakpoints = useBreakpoint()

  const isAnchorLink = (url) => {
    // Your logic to check if the URL is an anchor link (e.g., starts with "#")
    return url.startsWith('#');
  };

  return(
    <div className="page-complex" id={post?.field_page_css_id ? `${post?.field_page_css_id }` : ''}>
    <Banner
      post={post}
      height={bannerHeight}
      hasButton={true}
      buttonType="anchor"
      multipleButtons={multipleButtons}
    />
    <div className={`${post?.field_page_css_id} ${post?.drupal_internal__nid === 3303 ? 'row' : ''}`}>
    {contents && contents?.edges.length > 0 && contents.edges.map(({node}, index) => {
      return(
        <section
          className={`section-to-know-us background-${node?.field_background_color} p-t-80 p-b-80 medium-p-b-54 small-p-t-24 small-p-l-r-16 small-p-b-64 ${node?.field_variation && node?.field_variation == 'infografia' ? 'col-md-4' : ''}`}
          key={index}
          id={node?.field_anchor_id}
        >
            <div className={`${node?.field_content_width === 'boxed' ? 'container' : ''} p-0 contents`}>

              {
                node?.field_variation && node?.field_variation == 'infografia'

              ?
                null
              :
                <h2 className={`H2-Medium-C---${node?.field_heading_color} ${node?.field_image_position === 'center' ? 'm-b-32' :  'p-b-88'} medium-m-b-74 small-m-b-16 medium-p-b-0 small-p-b-0 text-center`}>
                  {node.title}
                </h2>
              }

                <SectionContents
                  post={node}
                  imagePos={node?.field_image_position}
                  color={node?.field_heading_color}
                  variation={node?.field_variation}
                />
            </div>
        </section>
        )
      })}
      </div>

    </div>
  )
}

const SectionContents = (props) => {
  const {post, imagePos, color, id, variation} = props
  const breakpoints = useBreakpoint()

  if(variation && variation === 'infografia'){
    return(
      <div className="card shadow px-4 py-1 mb-6">
          <div className="card-body text-center text-md-left">
              <div className="row align-items-center">
                  <div className="col-md-12">
                      <Icon icon={`icons-32-x-32-export-person`} size={60} />
                      <h3 className="mb-3">{post?.title}</h3>
                      <p
                        dangerouslySetInnerHTML={{__html: post?.body?.value}}
                        className="mb-4"
                      />
                  </div>
              </div>
          </div>
      </div>
    )
  }

if(imagePos === "left"){
  return(
    <>
    <div className="row img-left contents-item m-b-64 medium-m-b-114" id={id}>
       <div className={`col-12 col-sm-6 background-${post?.field_image_background_color} first-item max-width-683 image-wrapper`}>
          <img
            src={post?.relationships?.field_image?.image_style_uri?._653_432}
            alt={post?.field_cta?.title}
            className="m-r--24"
          />
       </div>
       <div className="col-12 col-sm-6 p-l-80 p-r-169 medium-p-48-24 small-p-0">
          <div className="m-b-32-" dangerouslySetInnerHTML={{__html: post?.body?.value}} />
       </div>
    </div>
    <div className="row">
       <div className="container">
          <div className="col-12 button-cta align-center d-flex justify-content-center">

              <DynamicLinks
                links={!Array.isArray(post?.field_cta) ? [post?.field_cta] : post?.field_cta}
                color={post?.field_heading_color}
                formId={post?.relationships?.webform?.drupal_internal__id}
                backgroundImage={post?.relationships?.field_image?.image_style_uri?.wide}
                formData={post?.relationships?.webform}
              />

          </div>
       </div>
    </div>
   </>
  )
  }

  if(imagePos === "center" || imagePos === "centre"){
    return(
      <div className="col-12">
        <div className="H5-Medium-C---black volunteer-body-intro text-center m-b-80- medium-p-0  medium-m-b-48 medium-p-l-r-24 small-p-l-r-0 small-m-b-32-">
          <div
           className="m-b-32 max-width-683 m-auto"
           dangerouslySetInnerHTML={{__html: post?.body?.value}}
          />

          <div className="row">
             <div className="container">
                <div className="col-12 button-cta">
                      <DynamicLinks
                        links={!Array.isArray(post?.field_cta) ? [post?.field_cta] : post?.field_cta}
                        color={post?.field_heading_color}
                        formId={post?.relationships?.webform?.drupal_internal__id}
                        backgroundImage={post?.relationships?.field_image?.image_style_uri?.wide}
                        formData={post?.relationships?.webform}
                      />
                      {post?.relationships?.field_image &&
                        <div className="image-container d-flex justify-content-center">
                          <img
                            src={post?.relationships?.field_image?.image_style_uri?._653_432}
                            alt={post?.field_cta?.title}
                          />
                        </div>
                      }
                </div>
             </div>
          </div>
        </div>
      </div>
    )
  }

  if(imagePos === 'right'){
    return(
      <div className="row img-right toknowus-item pastoral-team">
         <div className="col-12 col-sm-6 p-l-169 p-r-82 m-r-3p medium-p-24-48 small-p-0">
              {(breakpoints.xs480 || breakpoints.xs_sm  /*|| breakpoints.sm_md*/) &&
                <div className={`col-12 col-sm-6 background-${post?.field_image_background_color} first-item max-width-683 image-wrapper`}>
                   <img
                     src={post?.relationships?.field_image?.image_style_uri?._653_432}
                     alt={post?.field_cta?.title}
                     className="m-r--24"
                   />
                </div>
              }


            <div className="m-b-32" dangerouslySetInnerHTML={{__html: post?.body?.value}} />
            <div className="row hide-">
               <div className="container">
                  <div className="col-12 button-cta align-left">
                    <DynamicLinks
                      links={!Array.isArray(post?.field_cta) ? [post?.field_cta] : post?.field_cta}
                      color={post?.field_heading_color}
                      formId={post?.relationships?.webform?.drupal_internal__id}
                      backgroundImage={post?.relationships?.field_image?.image_style_uri?.wide}
                      formData={post?.relationships?.webform}
                    />
                  </div>
               </div>
            </div>
         </div>
         {breakpoints.xxl &&
           <div className="col-12 col-sm-6 background-bordeux second-item max-width-683 small-p-0">
              <img
                src={post?.relationships?.field_image?.image_style_uri?._653_432}
                alt={post?.field_cta?.title}
                className="m-r--24"
              />
           </div>
       }
      </div>
    )
  }


}



const SectionToVisitUs = (props) => {
  const {title, post, imagePos, pos, t} = props
  const breakpoints = useBreakpoint()

if(imagePos === "left" && pos === 1){
  return(
    <div className="row img-left visit-us-item m-b-64 medium-m-b-83">
       <div className="col-6 first-item max-width-683">
          <img
            src={post?.relationships?.field_visit_image_1?.image_style_uri?._653_432}
            alt={post?.field_visit_cta_1?.title}
            className="m-r--24"
          />
       </div>
       <div className="col-6 p-l-56 p-r-169 medium-p-l-r-25">
          <div className="m-b-32- m-t-88 medium-m-t-0 small-m-t-0-" dangerouslySetInnerHTML={{__html: post?.field_visit_body_1?.value}} />
       </div>
    </div>
  )
  }

    return(
      <div className="row  second m-b-66- medium-m-r-2 p-t-208 medium-p-t-0">
        <div className="col-md-6 second-first m-w-625 p-l-r-0- m-r-75- p-l-12 m-r-63 ">
          <div className="ministry-image background-beig read-more m-b-42">
            <img
              src={post?.relationships?.field_visit_image_3?.image_style_uri?._653_432}
              alt={post?.field_visit_cta_3?.title}
              className="m-t--24 m-l-112 m-w-540"
            />
            <Button2
             title={t("Veure més")}
             icon="icons-32-x-32-generic-plus"
             iconSize={16}
             iconColor="#ff0"
             iconClassName="icon"
             className="r-more green"
             onClick={() => navigate(post?.items[0]?.node?.path?.alias)}
             />
          </div>
          <div className="ministry-title-desc">
            <div dangerouslySetInnerHTML={{__html: post?.field_visit_body_3?.value}} />
          </div>
        </div>
        <div className="col-md-6 second-second m-t--304 m-w-625 p-l-r-0 ">
          <div className="ministry-image background-beig read-more m-b-42">
            <img
              src={post?.relationships?.field_visit_image_2?.image_style_uri?._653_432}
              alt={post?.field_visit_cta_2?.title}
              className="m-t--24 m-l-112 m-w-540"
            />
            <Button2
             title={t("Veure més")}
             icon="icons-32-x-32-generic-plus"
             iconSize={16}
             iconColor="#ff0"
             iconClassName="icon"
             className="r-more green"
             onClick={() => navigate(post?.items[0]?.node?.path?.alias)}
             />
          </div>
          <div className="ministry-title-desc">
            <div dangerouslySetInnerHTML={{__html: post?.field_visit_body_2?.value}} />
          </div>

          <div className="third p-t-80">
          <div className="ministry-image background-beig read-more m-b-42">
            <img
              src={post?.relationships?.field_visit_image_4?.image_style_uri?._653_432}
              alt={post?.field_visit_cta_3?.title}
              className="m-t--24 m-l-112 m-w-540"
            />
            <Button2
             title={t("Veure més")}
             icon="icons-32-x-32-generic-plus"
             iconSize={16}
             iconColor="#ff0"
             iconClassName="icon"
             className="r-more green"
             onClick={() => navigate(post?.items[0]?.node?.path?.alias)}
             />
          </div>
          <div className="ministry-title-desc">
            <div className="m-b-8" dangerouslySetInnerHTML={{__html: post?.field_visit_body_4?.value}} />
            <div className="unida-social-media">
              <div dangerouslySetInnerHTML={{__html: post?.field_visit_social_media_body?.value}} />
            </div>
          </div>
          </div>
        </div>


      </div>
    )

}

const SectionToVisitUsMobile = (props) => {
  const {title, post, imagePos, pos, t} = props

if(imagePos === "left" && pos === 1){
  return(
    <div className="row img-left visit-us-item m-b-64 medium-m-b-83 first medium-p-l-r-24 small-m-b-20">
       <div className="col-12 col-sm-6 first-item max-width-683 small-m-b-8">
          <img
            src={post?.relationships?.field_visit_image_1?.image_style_uri?._653_432}
            alt={post?.field_visit_cta_1?.title}
            className="m-r--24"
          />
       </div>
       <div className="col-12 col-sm-6">
          <div className="m-b-32- m-t-88 medium-m-t-0 small-m-t-0 small-m-b-0" dangerouslySetInnerHTML={{__html: post?.field_visit_body_1?.value}} />
       </div>
    </div>
  )
  }


    return(
      <>
      <div className="row second small-m-b-20"> {/* first */}
        <div className="col-12 col-sm-12 ministry-image background-beig read-more m-b-42 medium-m-w-348 medium-m-b-82">
          <img
            src={post?.relationships?.field_visit_image_3?.image_style_uri?._653_432}
            alt={post?.field_visit_cta_3?.title}
            className="m-t--24 m-l-112 m-w-540 medium-m-l-24 medium-m-w-348"
          />
          <Button2
           title={t("Veure més")}
           icon="icons-32-x-32-generic-plus"
           iconSize={16}
           iconColor="#ff0"
           iconClassName="icon"
           className="r-more green"
           onClick={() => navigate(post?.items[0]?.node?.path?.alias)}
           />
        </div>
        <div className="col-12 col-sm-12 ministry-title-desc medium-p-l-64 medium-p-r-24 small-p-l-r-0">
          <div dangerouslySetInnerHTML={{__html: post?.field_visit_body_3?.value}} />
        </div>
      </div>

      <div className="row third"> {/* second */}
        <div className="col-12 col-sm-6 ministry-image background-beig read-more m-b-42 medium-m-w-348 medium-m-b-82">
          <img
            src={post?.relationships?.field_visit_image_2?.image_style_uri?._653_432}
            alt={post?.field_visit_cta_2?.title}
            className="m-t--24 m-l-112 m-w-540 medium-m-l-24 medium-m-w-348"
          />
          <Button2
           title={t("Veure més")}
           icon="icons-32-x-32-generic-plus"
           iconSize={16}
           iconColor="#ff0"
           iconClassName="icon"
           className="r-more green"
           onClick={() => navigate(post?.items[0]?.node?.path?.alias)}
           />
        </div>
        <div className="col-12 col-sm-6 ministry-title-desc medium-p-l-64 medium-p-r-24 small-p-l-r-0">
          <div dangerouslySetInnerHTML={{__html: post?.field_visit_body_2?.value}} />
        </div>
      </div>

      <div className="row forth"> {/* third */}

        <div className="col-12 col-sm-6 ministry-image background-beig read-more m-b-42 medium-m-w-348 medium-m-b-82">
          <img
            src={post?.relationships?.field_visit_image_4?.image_style_uri?._653_432}
            alt={post?.field_visit_cta_3?.title}
            className="m-t--24 m-l-112 m-w-540 medium-m-l-24 medium-m-w-348"
          />
          <Button2
           title={t("Veure més")}
           icon="icons-32-x-32-generic-plus"
           iconSize={16}
           iconColor="#ff0"
           iconClassName="icon"
           className="r-more green"
           onClick={() => navigate(post?.items[0]?.node?.path?.alias)}
           />
        </div>
        <div className="col-12 col-sm-6 ministry-title-desc medium-p-l-64 medium-p-r-24 small-p-l-r-0">
          <div className="m-b-8" dangerouslySetInnerHTML={{__html: post?.field_visit_body_4?.value}} />
          <div className="unida-social-media">
            <div dangerouslySetInnerHTML={{__html: post?.field_visit_social_media_body?.value}} />
          </div>
        </div>
      </div>

      </>
    )

}

const SectionConnectUs = (props) => {
  const {
    t,
    pos,
    imageFirst,
    titleFirst,
    descFirst,
    urlFirst,
    imageSecond,
    titleSecond,
    descSecond,
    urlSecond
  } = props

  const breakpoints = useBreakpoint()

  return(
    <div className={`row small-p-l-r-0 ${ pos === 1 ? 'm-b-80' : ''} medium-m-b-40 small-m-0 small-m-b-32-`}>
      <div className={`${breakpoints.md ? 'col-sm-2' : 'col-sm-3'}`}></div>
      <div className={`${breakpoints.md ? 'col-sm-4' : 'col-sm-3'} col-6 ministry-item`}>
        <div>
          <div className="ministry-image background-white small-m-b-4">
            <img
              src={imageFirst}
              alt={titleFirst}
              className="m-r--24"
            />
          </div>
        </div>
        <div className="p-r-60 small-p-0">
          <h2 className="title ministry-title m-b-8"> {titleFirst} </h2>
          <div className="ministry-desc">
            <div className="m-b-16" dangerouslySetInnerHTML={{__html: descFirst }} />
          </div>
            <Button2
             title={t("Veure més")}
             icon="icons-32-x-32-generic-plus"
             iconSize={16}
             iconColor="#ff0"
             iconClassName="icon"
             className="r-more orange right"
             //onClick={() => navigate(post?.items[0]?.node?.path?.alias)}
             />
        </div>
      </div>

      <div className={`${breakpoints.md ? 'col-sm-4' : 'col-sm-3'} col-6 ministry-item`}>
        <div>
          <div className="ministry-image background-white small-m-b-4">
            <img
              src={imageSecond}
              alt={titleSecond}
              className="m-r--24"
            />
          </div>
        </div>
        <div className="p-r-60 small-p-0">
          <h2 className="title ministry-title m-b-8"> {titleSecond} </h2>
          <div className="ministry-desc">
          <div className="m-b-16" dangerouslySetInnerHTML={{__html: descSecond }} />
          </div>
          <Button2
           title={t("Veure més")}
           icon="icons-32-x-32-generic-plus"
           iconSize={16}
           iconColor="#ff0"
           iconClassName="icon"
           className="r-more bordeaux right"
           //onClick={() => navigate(post?.items[0]?.node?.path?.alias)}
           />
        </div>
      </div>
      <div className={`${breakpoints.md ? 'col-sm-2' : 'col-sm-3'}`}></div>

    </div>
  )
}

const SectionMakeDifference = (props) => {
  const {
    t,
    pos,
    imageFirst,
    titleFirst,
    descFirst,
    urlFirst,
    imageSecond,
    titleSecond,
    descSecond,
    urlSecond
  } = props

  const breakpoints = useBreakpoint()

  return(
    <div className={`row small-p-l-r-0 ${ pos === 1 ? 'm-b-80-' : ''} medium-m-b-40 small-m-0`}>
      {!breakpoints.md &&
        <div className="col-sm-2"></div>
      }

      <div className={`${breakpoints.md ? 'col-sm-6' : 'col-sm-4 col-6'} ministry-item small-m-b-24`}>
        <div>
          <div className="ministry-image background-green m-b-16 small-m-b-10">
            <img
              src={imageFirst}
              alt={titleFirst}
              className="m-r--24"
            />
          </div>
        </div>
        <div className="p-r-60 small-p-0">
          <h2 className="title ministry-title m-b-8"> {titleFirst} </h2>
          <div className="ministry-desc m-b-31">
            <div dangerouslySetInnerHTML={{__html: descFirst }} />
          </div>
            <Button2
             title={t("Troba un ministeri")}
             className="r-more green right"
             //onClick={() => navigate(post?.items[0]?.node?.path?.alias)}
             />
        </div>
      </div>

      <div className={`${breakpoints.md ? 'col-sm-6' : 'col-sm-4 col-6'} ministry-item`}>
        <div>
          <div className="ministry-image background-green m-b-16 small-m-b-10">
            <img
              src={imageSecond}
              alt={titleSecond}
              className="m-r--24"
            />
          </div>
        </div>
        <div>
          <h2 className="title ministry-title m-b-8"> {titleSecond} </h2>
          <div className="ministry-desc m-b-31 small-m-b-24">
          <div dangerouslySetInnerHTML={{__html: descSecond }} />
          </div>
          <Button2
           title={t("Les nostres missions")}
           className="r-more green right"
           //onClick={() => navigate(post?.items[0]?.node?.path?.alias)}
           />
        </div>
      </div>
      {!breakpoints.md &&
        <div className="col-sm-2"></div>
      }

    </div>
  )
}


 export default ComplexPage
