import React from 'react'
//import PropTypes from 'prop-types'
//import *as Styles from "../assets/css/ui-kit.css"

const Title = (props) => {
    var style_name = props.color + " weight-" + props.weight + " " + props.className;

    var html_title = <h5 className={style_name}>{props.title}</h5>

    if(props.type === 1){
        html_title = <h1 className={style_name}>{props.title}</h1>
    }
    else if(props.type === 2){
        html_title = <h2 className={style_name}>{props.title}</h2>
    }
    else if(props.type === 3){
        html_title = <h3 className={style_name}>{props.title}</h3>
    }
    else if(props.type === 4){
        html_title = <h4 className={style_name}>{props.title}</h4>
    }
    else if (props.type === 5){
        html_title = <h5 className={style_name}>{props.title}</h5>
    }
    else if (props.type === 6){
        html_title = <p className={style_name}>{props.title}</p>
    }
    else if (props.type === 7){
        html_title = <p className={style_name}><small>{props.title}</small></p>
    }


    return(
       html_title
    )
}


export default Title
