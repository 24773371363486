import React, { useContext, useState } from 'react'
import { Link, navigate } from 'gatsby'
import { LanguageContext } from '../../context'
import { homePagePaths, translate } from '../../translate'
import Modal from "../modal"
import ToastMessage from "../toast"
import { useToasts } from "react-toast-notifications"
import PropTypes from "prop-types"
import Icon from "../icon"


const ButtonCtaTopContent = (props) => {

  const {
    lang,
    backgroundimage,
    icon,
    iconClassName,
    iconSize,
    iconColor,
    formId,
    modalTitle,
    modalDescription,
    modalFooterText,
    formData,
    project
  } = props

  const [show, setShow] = useState(false)
  const handleShow = () => setShow(true)

  const [modalShow, setModalShow] = useState(false)
  const [messageShow, setMessageShow] = useState(false)

  const { t } = useContext(LanguageContext)


  const modalFooter = () => {
    return(
      <>
        <p>{t(modalFooterText)}</p>
      </>
    )
  }

  const sendMessage = (response) => {
    setModalShow(false)
    setMessageShow(true);
  }

  const { addToast } = useToasts()

  const {title=t("Visita'ns"), className="btn btn-outline btn-outline-visit-us my-2 my-sm-0"} = props

  return (
   <>
     <div
     className="col-md-12 call-to-action">
       <div className="row">
         <div className="col-md-12 p-0">
           <button
           className={className}
           type="submit"
           onClick={() => setModalShow(true)}
           >{title}
           {icon &&
             <Icon
               icon={icon}
               className={iconClassName}
               size={iconSize}
               color={iconColor}
             />
           }
           </button>
         </div>
       </div>
     </div>

     <ToastMessage
      show={messageShow}
      title={t("Dades enviades")}
      message={t("Dades enviades correctament. Aviat es posarà en contacte amb tu una persona de la Fundació Goel. Gràcies!")}
      onClose={() => setMessageShow(false)}
      position="top-center"
      lang={props?.lang}
      />
     <Modal
       title={t(modalTitle)}
       description={t(modalDescription)}
       show={modalShow}
       onHide={() => setModalShow(false)}
       backgroundimage={backgroundimage}
       size='lg'
       className="modal-unida"
       theform={formId}
       formData={formData}
       footer={modalFooter()}
       sendMessage={(response) => sendMessage(response)}
       lang={props?.lang}
       id="visit_us_goel_webform"
       project={project}
       formId={formId}
       />
   </>
 )
}

ButtonCtaTopContent.propTypes = {
  formId: PropTypes.string,
  modalTitle: PropTypes.string,
  modalDescription: PropTypes.string,
  modalFooterText: PropTypes.string,
}

ButtonCtaTopContent.defaultProps = {
  formId: `visit_us_goel_webform`,
  modalTitle: "Com podem pregar per tu?",
  modalDescription: "Omple el següent formulari especificant la teva petició d'oració.",
  modalFooterText: `"Si necessites ajuda, posa’t en contacte amb nosaltres mitjançant el número de telèfon (+34) 937 88 16 61, de dilluns a diumenge de 9:00 a 17:00, o envia’ns un correu electrònic a info@fundaciogoel.es."`,
}

export default ButtonCtaTopContent
