import React from 'react';
import { Link } from 'gatsby';
import Button from '../../components/buttons/button-modal'
import Utils from '../../utils'

const DynamicLinks = (props) => {
  const { links, color, backgroundImage, formId, formData } = props;

  const isInternalUrl = (url) => {
    // Your logic to check if the URL is internal (e.g., starts with "/")
    return url && url.url.startsWith('/');
  };

  const isExternalUrl = (url) => {
    return url && !isInternalUrl(url.url);
  };

  const isAnchorLink = (url) => {
    // Your logic to check if the URL is an anchor link (e.g., starts with "#")
    return url?.url.startsWith('#');
  };

  const isModal = (url) => {
    return url?.uri === 'route:<nolink>';
  };

  return (
    <div>
      {links &&
        links.length > 0 &&
        links.map((link, index) => {
          if (isAnchorLink(link)) {
            return (
              <a
                key={index}
                href={link.url}
                className={`btn btn-outline Normal-Bold-L---${color} ${color} m-b-56 p-r-0 m-r-24`}
              >
                {link.title}
              </a>
            );
          } else if (isInternalUrl(link)) {
            return (
              <Link
                key={index}
                to={Utils.removeCaUrl(link.url)}
                className={`btn btn-outline Normal-Bold-L---${color} ${color} m-b-56  p-r-0 m-r-24`}
              >
                {link.title}
              </Link>
            );
          } else if (isModal(link)) {
            return (
              <Button
                key={index}
                type="button"
                className={`btn btn-outline Normal-Bold-L---${color}  p-r-0 m-r-24`}
                data-bs-toggle="modal"
                data-bs-target={link.url.substring('modal:'.length)} // Extract the modal target from the URL
                title={link.title}
                backgroundimage={backgroundImage}
                theform={formId}
                formData={formData}
              />
            );
          } else {
            if(link?.url){
              return (
                <a
                  key={index}
                  href={link?.url}
                  className={`btn btn-outline Normal-Bold-L---${color} ${color}  p-r-0 m-r-24`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {link?.title}
                </a>
              );
            }
          }
        })}
    </div>
  );
};

export default DynamicLinks;
